export enum Pages {
  Dashboard = "/dashboard",
  DashboardAllTrainees = "/dashboard/all-trainees",
  DashboardTrainee = "/dashboard/trainee/:userId",
  DashboardAirmanship = "/dashboard/airmanship",
  SessionsOverview = "/sessions-overview",
  SessionsList = "/sessions-list",
  Sessions = "/sessions",
  SessionDetail = "/session",
  Overview = "/overview",
  Trainees = "/trainees/",
  TraineesWithTab = "/trainees/:tab",
  TraineesActive = "/trainees/active",
  TraineesPending = "/trainees/pending",
  AddTrainee = "/add-trainee",
  AddAndAssign = "/add-and-assign",
  Support = "/support",
  Settings = "/settings",
  Authentication = "/auth",
  Promo = "/promo"
}
