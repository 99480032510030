import { Input, Label, RadioWrapper } from "./styles";
import { useMemo, useState } from "react";
import { StyledRadioButton } from "../../../../components/ui/styled-radio";
import CombinedChart from "./combined-chart-firing";
import Message from "../../../../components/ui/message";
import { FormattedMessage, useIntl } from "react-intl";
import useQueryParams from "../../../../utils/use-query-params";
import { useQuery } from "react-query";
import { getCombinedCharts } from "../../../../api/sessions";
import { CustomTooltip, LoaderContainer, StyledImg, Title, TooltipContent } from "../../session-detail.styles";
import Loader from "../../../../components/ui/loader";
import { Container } from "./styles";
import TooltipSVG from "../../../../components/icons/tooltip.svg";
import { ChartData } from "../../../../types/combined-chart";

type chartProps = {
  [k: string]: {
    label: string;
    color: string;
  } | undefined;
};

export type chartState = {
  [k: string]: boolean | undefined;
};

const TimelineChartFiring = () => {
  const intl = useIntl();
  const params: any = useQueryParams();
  let userId = params.get("userId");
  let from: string = params.get("from");
  let to: string = params.get("to");
  let sessionId: string = params.get("sessionId");
  let simulationInstanceId: string = params.get("simulationInstanceId");
  let scenarioInstanceId: string = params.get("scenarioInstanceId");

  const {isLoading, data } = useQuery(
    ["getCombinedCharts", userId, from, to, sessionId, simulationInstanceId, scenarioInstanceId],
    () =>
    getCombinedCharts({ from, to, sessionId, userId, simulationInstanceId, scenarioInstanceId }),
    {
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
    }
  );

  const NoData = () => <Message>
    <FormattedMessage id="no-data" />
  </Message>;
 if (isLoading) return <LoaderContainer><Loader /></LoaderContainer>;
 if (!data) return <Container><NoData /></Container>;

 const timelineDataSets: any = useMemo(() => data.filter((dataSet:any) => dataSet.chartType === "Timeline"), [data]);
 const timelineChartData: ChartData[] = timelineDataSets.flatMap((chart:any) => chart.datasets);

 const areaChartData: ChartData[] = useMemo(() => timelineChartData.filter((chart: ChartData) => chart.type === "area"), [timelineChartData]);
 const scatterChartData: ChartData[] = useMemo(() => timelineChartData.filter((chart: ChartData) => chart.type === "scatter"), [timelineChartData]);

 const sortedDataForRadio = timelineChartData.sort((a: ChartData,b: ChartData) => a.position - b.position);
 const chartTypes: chartProps = sortedDataForRadio.reduce((acc:any, obj:any) => {
    if(obj.data.length !== 0){
      acc[obj.name] = {
        label: obj.radioLabel,
        color: obj.color,
        position: obj.position
      };
    } 
    return acc;
  }, {});

  //setting the state for each chart for toggle
  const chartState: chartState = {}
  for (let key in chartTypes) {
    chartState[key] = true
  }
  const [chartStateByType, setChartStateByType] = useState<chartState>(chartState);

  const changeHandler = (chartType: string) => {
    setChartStateByType((prevState) => ({
      ...prevState,
      [chartType]: !prevState[chartType]
    }));
  };

  //if area chart data is empty, do not load component
  if (areaChartData.length === 0) return null

  return (
    <Container>
       <Title>
        <FormattedMessage id={"session-detail:timeline"} />
        <CustomTooltip
            title={
                <TooltipContent>
                <StyledImg src={TooltipSVG} width="14px" />
                {timelineDataSets?.[0]?.description}
                </TooltipContent>
            }
            arrow
            >
          <StyledImg alt="tooltip" src={TooltipSVG} width="18px" />
        </CustomTooltip>
      </Title>

      <RadioWrapper>
        {Object.entries(chartTypes).map(([chartType, chartProps]) => (
          <Label key={chartType} htmlFor={chartType} className={chartStateByType[chartType] ? '' : 'unchecked'}>
            <Input
              type="checkbox"
              defaultChecked
              id={chartType}
              onChange={() => changeHandler(chartType)}
            />
            <StyledRadioButton
              selected={!!chartStateByType[chartType]}
              color={chartProps?.color}
            ></StyledRadioButton>
            {chartProps?.label}
          </Label>
        ))}
      </RadioWrapper>

      <CombinedChart
        areaChartData={areaChartData}
        scatterChartData={scatterChartData}
        chartStateByType={chartStateByType}
      />
    </Container>
  );
};

export default TimelineChartFiring;
