
import Avatar from "../../components/ui/avatar";
import { useUser } from "../../context/user";
import { AvatarContainer, Container, Name } from "./styles";

const UserBadge = () => {
    const { state: user } = useUser();
    return (
        <Container>
            <AvatarContainer>
                <Avatar
                    size="large"
                    editable={false}
                    name={user.name}
                    photo={user.photo} />
                <Name>
                    <div style={{ color: '#F2F2F2', lineHeight: '27px', fontSize: '18px' }}>
                        {user.name}
                    </div>
                    <div style={{ color: '#999', fontSize: '16px', lineHeight: '24px' }}>
                        {user.email}
                    </div>
                </Name>
            </AvatarContainer>
        </Container>
    );
}

export default UserBadge;
