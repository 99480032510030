import React, { useCallback } from 'react';
import Text from "../../components/ui/text";
import getNavigationItems from "./get-navigation-items";
import { Container, IconWrapper, Logo, Menu, MenuContainer, MenuItem, Nav, Wrapper, SectionTitle, MenuItemIndicator, MenuLink } from "./navigation.styles";
import { useMsal } from "@azure/msal-react";
import { useHistory } from 'react-router-dom'; // Import useHistory

const Navigation = () => {
  const { instance } = useMsal();
  const sections = getNavigationItems();
  const history = useHistory(); // Initialize useHistory

  const isActive = (path: string) => {
    return window.location.pathname.includes(path);
  };

  const handleChangePassword = useCallback(() => {
    instance.loginRedirect({
        authority: process.env.REACT_APP_MSAL_PASSWORD_CHANGE_AUTHORITY,
        scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPE],
    });
  }, [instance]);

  // Function to handle navigation
  const handleNavigation = (url: string) => {
    history.push(url);
  };

  return (
    <Wrapper>
      <Container>
        <Nav>
          <Logo src="/assets/heat-side-menu-logo.svg" />
          <MenuContainer>
            {sections.map((section: any) => (
              <React.Fragment key={section.code}>
                <SectionTitle>{section.title}</SectionTitle>
                <Menu>
                  {section.navigationItems.map(({ url, icon: Icon, title, code, enabled }: any) => (
                    enabled && (
                      <MenuItem
                        key={code}
                        onClick={code === "setting-page" ? handleChangePassword : () => handleNavigation(url)}
                      >
                        <MenuItemIndicator isActive={isActive(url)} className='indicator' />
                        <MenuLink className={isActive(url) ? 'active' : ''}>
                          <IconWrapper>
                            <Icon width="28" height="28" />
                          </IconWrapper>
                          <Text variant="regular">{title}</Text>
                        </MenuLink>
                      </MenuItem>
                    )
                  ))}
                </Menu>
              </React.Fragment>
            ))}
          </MenuContainer>
        </Nav>
      </Container>
    </Wrapper>
  );
};

export default Navigation;

