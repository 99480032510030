import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  position: fixed;
  height: 121px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  border-bottom: 1px solid #3F3F3F;
  background: #2B282A;

  @media (max-width:1024px) {
    height: auto;
    width: 100%;
    padding-bottom: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1.5rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > svg:first-child {
    margin-right: 0.75rem; 
  }

  @media (max-width:1024px) {
    padding: 0;
  }
`;

export const LogoutWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border-radius: 2px;
  margin-right: 0.75rem;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #3F3F3F;
  }
`;