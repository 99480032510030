import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Page from "../../components/ui/page";
import { Container, DetailItemText, TimeIconContainer, BulletPoint, SessionsContainer, SessionsMainContent, SessionsSidebar, LoaderContainer } from "./styles";
import ChevronRightNoPadding from "../../components/icons/chevron-right-no-padding";
import Users from "../../components/icons/users";
import Time from "../../components/icons/time";
import { FiltersContainer, SessionItemContainer, SessionIconContainer, SessionInfoContainer, SessionTitle, SessionDetails, DetailItem, ChevronContainer, LoaderFixedContainer } from './styles'
import { useState, useEffect, useRef, useCallback } from "react";
import { useUser } from "../../context/user";
import TraineesFilter from "./filters/trainees";
import { Roles } from "../../enums/user";
import { FiltersList } from "../../enums/dashboard";
import DateFilter from "./filters/date";
import { getSessionData } from "../../api/dashboard";
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from "react-query";
import { getSessions } from "../../api/dashboard";
import Loader from "../../components/ui/loader";
import { getTimeDifference } from "../session-detail/components/timeline-chart-with-firing/utils";

interface FiltersState {
  userId: string | null;
  from: string | null;
  to: string | null;
  pageNumber?: number | null;
  dateRange?: string | null;
}

const Filters = ({ onUserSelected, onDateChange, initialUserId, initialDateRange, dateRange }: { onUserSelected: (userId: string | null) => void, onDateChange: (from: Date, to: Date) => void, initialUserId: string | null, initialDateRange: { from: Date | null, to: Date | null }, dateRange?: string | null }) => {
  const { state: { role } } = useUser();
  const [filterOpen, setFilterOpen] = useState(FiltersList.None);

  return (
    <FiltersContainer>
      {
        role === Roles.Instructor && <TraineesFilter
          isOpen={FiltersList.TraineesList === filterOpen}
          handleOnClick={(val: any) => setFilterOpen(val)}
          onUserSelected={onUserSelected}
          initialUserId={initialUserId}
        />
      }
      <DateFilter
        isOpen={FiltersList.DatePicker === filterOpen}
        handleOnClick={(val: any) => setFilterOpen(val)}
        onChange={onDateChange}
        initialDateRange={initialDateRange}
        dateRange={dateRange}
      />
    </FiltersContainer>
  );
}

const SessionItem = ({ session, selectedUserId }: any) => {
  const history = useHistory();

  const duration = (from: any, to: any) => {
    return getTimeDifference(new Date(from), new Date(to));
  };

  const handleClick = () => {
    const { id, traineeName, simulationName, logoUrl, userId } = session;
    const { from, to, scenarioInstanceId, simulationId, scenarioName } = session.sessions[0];
    history.push(`/session?traineeName=${traineeName}&simulationName=${simulationName}&userId=${userId}&from=${from}&to=${to}&scenarioId=${simulationId}&simulationInstanceId=${id}&scenarioInstanceId=${scenarioInstanceId}&name=${scenarioName}&logoUrl=${logoUrl}`);
  };

  return (
    <SessionItemContainer onClick={handleClick}>
      <SessionIconContainer>
        {session.logoUrl && <img src={session.logoUrl} alt="Session Logo" />}
      </SessionIconContainer>
      <SessionInfoContainer>
        <SessionTitle>
          <span>{session.simulationName}</span>
          <BulletPoint className="bullet-point">&bull;</BulletPoint>
          <span>{session.sessions[0].scenarioName}</span>
        </SessionTitle>
        <SessionDetails>
          <DetailItem>
            <Users fill="#999999" />
            <DetailItemText>{session.traineeName}</DetailItemText>
          </DetailItem>
          <DetailItem>
            <TimeIconContainer>
              <Time fill="#999999" />
            </TimeIconContainer>
            <DetailItemText>{new Date(session.sessions[0].from).toLocaleString()}</DetailItemText>
          </DetailItem>
          <DetailItem>
            <BulletPoint>&bull;</BulletPoint>
            <span>{duration(session.sessions[0].from, session.sessions[0].to)}</span>
          </DetailItem>
        </SessionDetails>
      </SessionInfoContainer>
      <ChevronContainer className="chevron-container">
        <ChevronRightNoPadding />
      </ChevronContainer>
    </SessionItemContainer>
  );
}

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const SessionsPage = () => {
  const { state: { id: userId } } = useUser();
  const [selectedUserId, setSelectedUserId] = useState(userId);
  const [filters, setFilters] = useState<FiltersState>({
    userId: selectedUserId,
    from: null,
    to: null,
    pageNumber: null,
    dateRange: null,
  });
  const [sessionList, setSessionList] = useState<any[] | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const location = useLocation();
  const observer = useRef<IntersectionObserver>();

  const { isLoading, isError, error } = useQuery(
    ["sessionsList", filters],
    () => getSessions(filters),
    {
      suspense: false,
      refetchOnMount: false,
      useErrorBoundary: false,
      onSuccess: (newSessions) => {
        setSessionList((prevSessions) => prevSessions ? [...prevSessions, ...newSessions] : newSessions);
        setHasMore(newSessions.length > 0);
      },
    }
  );

  useEffect(() => {
    setSessionList(null);
    setHasMore(true);
  }, [filters.userId, filters.from, filters.to]);

  const lastSessionItemRef = useCallback((node: any) => {
    if (isLoading || !hasMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !isLoading && hasMore) {
        setFilters(prevFilters => ({
          ...prevFilters,
          pageNumber: prevFilters.pageNumber ? prevFilters.pageNumber + 1 : 2,
        }));
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const traineeId = queryParams.get('traineeId');
    const fromParam = queryParams.get('from');
    const toParam = queryParams.get('to');
    const dateRange = queryParams.get('dateRange');

    const initialFilters = {
      userId: traineeId,
      from: fromParam ? new Date(parseInt(fromParam)) : null,
      to: toParam ? new Date(parseInt(toParam)) : null,
      dateRange: dateRange,
    };

    setSelectedUserId(initialFilters.userId);

    setFilters({
      ...filters,
      userId: initialFilters.userId,
      from: initialFilters.from ? initialFilters.from.toISOString() : null,
      to: initialFilters.to ? initialFilters.to.toISOString() : null,
      dateRange: initialFilters.dateRange,
    });
  }, [location.search]);

  const handleUserSelected = (userId: string | null) => {
    setSelectedUserId(userId);
    setSessionList(null);
    setFilters({ ...filters, userId, pageNumber: 1 });
  };

  const handleDateChange = (from: Date, to: Date) => {
    const formatDate = (date: Date) => {
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).format(date);
    };
    setSessionList(null);
    setFilters((prevFilters) => ({
      ...prevFilters,
      from: formatDate(from),
      to: formatDate(to),
      pageNumber: 1,
    }));
  };

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <VRIntlProviderComponent localeFn={localeFn} id="sessions-list-page" fallback={null}>
      {sessionList === null  && (
        <LoaderFixedContainer>
          <Loader />
        </LoaderFixedContainer>
      )}
      <Page title="Sessions" rightContent= {
          <Filters
            onUserSelected={handleUserSelected}
            onDateChange={handleDateChange}
            initialUserId={selectedUserId}
            initialDateRange={{ from: filters.from ? new Date(filters.from) : null, to: filters.to ? new Date(filters.to) : null }}
            dateRange={filters.dateRange}
          />} >

        <Container>
          <SessionsContainer>
            <SessionsMainContent>
              {(sessionList || []).map((session: any, index: number) => (
                index + 1 === (sessionList || []).length ? (
                  <div ref={lastSessionItemRef} key={session.id}>
                    <SessionItem session={session} selectedUserId={selectedUserId} />
                  </div>
                ) : (
                  <SessionItem key={session.id} session={session} selectedUserId={selectedUserId} />
                )
              ))}
              {isLoading && sessionList && sessionList.length > 0 && (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )}
            </SessionsMainContent>
            {/* <SessionsSidebar></SessionsSidebar> */}
          </SessionsContainer>
        </Container>
      </Page>
    </VRIntlProviderComponent>
  );
};

export default SessionsPage;
