import Timeline from '../../../../components/icons/timeline-tooltip.svg'
import { getTimeDifference } from './utils'

export const series: any = [];

export const scatterSeries: any = [];

export const areaOptions: any = {
  chart: {
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false 
    },
    animations: {
      enabled: false,
    }
  },

  dataLabels: {
    enabled: false
  },

  legend: {
    show: false // Hide the legend
  },

  stroke: {
    curve: "smooth",
    width: 2
  },

  grid: {
    strokeDashArray: 8,
    borderColor: "#53555A"
  },

  xaxis: {
    type: "datetime",
    axisTicks: {
      show: false
    },
    labels: {
      show: false // Hide the x-axis labels
    },
    axisBorder: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  },

  tooltip: {
    enabled: true,
    x: {
      show: false
    },
    y: {
      show: false
    },
    custom: ({ seriesIndex, dataPointIndex, w }: any) => {
      const startDateTime =  new Date(w.globals.seriesX[seriesIndex][0]);
      const currentDateTime =  new Date(w.globals.seriesX[seriesIndex][dataPointIndex]);
      const seriesData = w.globals.initialSeries.filter((chart:any) => chart.name !== 'emptydata');

      const seriesTooltip: any[] = seriesData.map((series:any)=> {
        const data = series?.data[dataPointIndex] ? series?.data[dataPointIndex][1] : '';
        const {name, color, unit} = series;

        return `<span class=${data === '' ? 'hidden' : ''}>` +
            `<svg width="20" height="20" viewBox="0 0 20 20" style="margin-right:8px;">` +
            `<circle cx="12" cy="12" r="6" fill=${color} stroke="white" stroke-width="2"/></svg>`+
            `<span class='value-label'>` + name + `</span>` + data + unit + '</span>'
        });

      return '<div class="custom-tooltip">' +
        `<span><img src=${Timeline} width="22px" class="timeline"/>` + getTimeDifference(startDateTime, currentDateTime, true) + '</span>' +
        seriesTooltip.join('')
        + '</div>';
      }, 
  },

  yaxis: [],

  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0,
      gradientToColors: "#2B282A"
    }
  },

  annotations: {
    xaxis: []
  }
};

export const scatterOptions: any = { 
  chart: {
    type: 'scatter',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false 
    },
    animations: {
      enabled: false,
    }
  },

  legend: {
    show: false 
  },

  xaxis: {
    type: 'datetime',
    axisTicks: {
      show: false
    },
    labels: {
      show: false
    },
    axisBorder: {
      show: false,
      color: "#53555A",
    },
    tooltip: {
      enabled: false
    }
  },

  grid: {
    borderColor: "#53555A",
    show: true,
  },

  yaxis: {
    show: false,
    labels: {
      show: false
    },
    tickAmount: 0
  },

  markers: {
    strokeWidth: 1,
    size: 8,
    strokeColors: "#2b282a",
    hover: {
      size: 9,
      fillOpacity: 1
    }
  },

  tooltip: {
    enabled: true,
    x: {
      show: false
    },
    y: {
        show: false
    },
    custom: ({ seriesIndex, dataPointIndex, w }: any) => {
      const currentDateTime =  new Date(w.globals.seriesX[seriesIndex][dataPointIndex]);
      const series = w.globals.initialSeries[seriesIndex];
      const {color, radioLabel, startTime} = series;

      return `<div class="custom-tooltip">` +
        `<span><img src=${Timeline} width="22px" class="timeline"/>` + getTimeDifference(startTime, currentDateTime, true) + `</span>` +
        `<span><svg width="20" height="20" viewBox="0 0 20 20" style="margin-right:8px;"> <circle cx="12" cy="12" r="6" fill=${color} stroke="white" stroke-width="2"/></svg>` +
        `<span class='value-label'>` + radioLabel + `</span></span>` +
        '</div>';
      },
  },
}
