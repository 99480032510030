import styled from "styled-components";

export const TopStatWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
    margin-top: 1.5rem;
    flex-wrap: wrap;
`;

export const Section = {
    Container: styled.div`
        display: flex;
        border-radius: 8px;
        background: #2B282A;
        flex-direction: column;
        padding: 1.5rem;
        gap: 1.5rem;
        flex: 5;
    `,

    SubContainer: styled.div`
        display: flex;
        gap: 0.5rem;
        flex: 1;
        justify-content: space-evenly;
    `,

    Value: styled.span`
        color: #EE7127;
        text-align: center;
        font-size: 2.25rem;
        font-weight: 300;
        line-height: 3.375rem;
        letter-spacing: 0.0225rem;
    `,

    Wrapper: styled.span`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    `
}