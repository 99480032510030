import { Card } from "../../../../components/ui/Card";
import { BenefitPage } from "./index.styles";

interface BenefitStatsProps {
  benefitsItem: BenefitStats[];
}

export function BenefitStats({ benefitsItem }: BenefitStatsProps) {
  return (
    <BenefitPage>
      {benefitsItem.map((benefits: BenefitStats) => (
        <Card
          key={benefits.title}
          position="center"
          title={benefits.title}
          value={benefits.value}
          prefix={benefits.prefix}
          sufix={benefits.sufix}
        />
      ))}
    </BenefitPage>
  );
}
