import styled from "styled-components";

export const CalendarContainer = styled.div`
  width: 20.5rem;
  border-bottom: 1px solid #3F3F3F;
  padding: 1rem;
  margin-left: 1rem;
  box-sizing: border-box;

  .react-calendar__tile {
    margin: 0.25rem 0 !important;
    
    &.react-calendar__tile--now.react-calendar__tile--active {
      border: 0 !important;

      &:after {
        inset: 2px;
        border-radius: 32px;
        border: 1px solid black;
        z-index: 19;
      }
    }

    &--now {
      border-radius: 32px;
      border: 1px solid #7C3D18;
      background-color: transparent;
      color: unset;

      &.react-calendar__tile--hoverEnd {
        border-top: 1px dashed #999999;
        border-bottom: 1px dashed #999999;
        border-right: 1px dashed #999999;
        border-left: 0;
        border-radius: 0;
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
        background-color: transparent;
      }
  
      &.react-calendar__tile--hoverStart {
        border-top: 1px dashed #999999;
        border-bottom: 1px dashed #999999;
        border-left: 1px dashed #999999;
        border-right: 0;
        border-radius: 0;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
      }

      &:after {
        background-color: transparent !important;
      }
    }

    &--active {
      background-color: #642F10 !important;
      color: #F2F2F2;
      font-weight: 500;

      &.react-calendar__tile--rangeBothEnds {
        background-color: transparent !important;
      }
    }

    &--rangeStart, &--rangeEnd {
      color: #161215;
      font-weight: 700;

      > abbr {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #EE7127;
        border-radius: 32px;
      }
    }

    &--rangeEnd {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--hoverBothEnds {
      &:hover {
        border: 0;
      }
    }

    &:hover {
      background-color: transparent;

      > abbr {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #A9501C;
        border-radius: 32px;
      }
    }

    &--hover {
      border-top: 1px dashed #999999;
      border-bottom: 1px dashed #999999;
      background-color: transparent;
    }

    &--hoverEnd {
      border-top: 1px dashed #999999;
      border-bottom: 1px dashed #999999;
      border-right: 1px dashed #999999;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      background-color: transparent;
    }

    &--hoverStart {
      border-top: 1px dashed #999999;
      border-bottom: 1px dashed #999999;
      border-left: 1px dashed #999999;
      border-radius: 0;
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
    }
    
  }

  @media (max-width: 1023px) {
    border-left: none;
    padding: 2.3rem 2.3rem 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[100]};
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
  }
`;

export const PresetsList = styled.ul`
  min-width: 9.3rem;
  margin: 0;
  padding: 0.5rem;

  @media (max-width: 1023px) {
    min-width: 100%;
    display: flex;
  }
`;

export const Preset = styled.li`
  list-style: none;
  padding: 0 0.25rem;
  margin: 1rem 0;
  height: 1.5rem;
  display: flex;
  align-items: center;

  &:first-child {
    margin: 0;
  }

  @media (max-width: 1023px) {
    text-wrap: nowrap;
    -moz-text-wrap: nowrap;
    -webkit-text-wrap: nowrap;
  }
`;

export const PresetsContainer = styled.div`
  width: 14rem;
  box-shadow: -1px 0px 0px 0px #999 inset;

  @media (max-width: 1023px) {
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }
  }
`;

export const Link = styled.a<{ selected: boolean }>`
  padding: 0rem 0.25rem;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  border-radius: 0.2rem;
  color: ${({ selected }) => (selected ? '#EE7127' : 'inherit')};
  
  &:hover {
    color: #EE7127;
  }
`;

export const DateRangeLabel = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  margin: 0 0.2rem;
`;
