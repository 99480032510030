import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import ArrowLeft from "../../icons/arrow-left";
import Idas from "../../icons/idas.svg";
import Loader from "../loader";
import Styled from "../styled";
import Text from "../text";
import {
  Back,
  BreadcrumbContainer,
  Content,
  Description,
  Header,
  LeftCol,
  Main,
  PageLoaderContainer,
  RightCol,
  Title,
  TitleCard,
  TitleDetail,
  PageTitle,
  TitleImg,
  IconAndTitleWrapper
} from "./index.styles";
import useMediaQuery from "../../../utils/hooks/use-media-query";
import ChevronLeft from "../../icons/chevron-left";
import { useQuery } from "react-query";
import { getCustomerSettings } from "../../../api/settings";
import getNavigationItems from "../../../modules/navigation/get-navigation-items";

type Props = {
  title: string;
  pageTitle?: string | boolean;
  description?: string;
  rightContent?: ReactNode;
  code?: string;
  children: ReactNode;
  footer?: ReactNode;
  hasBackLink?: boolean;
  backLabel?: string;
  showDetails?: boolean;
  titleDetailText?: string;
  subtitleDetailText?: ReactNode;
  onBackClick?: () => void | undefined;
  showBackButton?: boolean;
  logoUrl?: string;
};

const Page = ({
  title,
  pageTitle,
  description,
  logoUrl,
  code,
  rightContent = null,
  footer,
  children,
  hasBackLink = false,
  backLabel,
  showDetails,
  subtitleDetailText = "",
  onBackClick,
  showBackButton = false
}: Props) => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const navItems = getNavigationItems();

  let currentPage: any;
  navItems.forEach((section: any) => {
    const foundItem = section.navigationItems.find((item: any) => item.code === code);
    if (foundItem) {
      currentPage = foundItem;
      return;
    }
  });

  const history = useHistory();
  const options = {
    suspense: false,
    refetchOnMount: false,
    useErrorBoundary: true
  };
  
  const { isLoading, data: d } = useQuery(
    [getCustomerSettings],
    getCustomerSettings,
    options
  );

  const handleBackButton = () => {
	  history.goBack();
  };

  return (
    <Main>
      <Header>
        {hasBackLink && (
          <Back onClick={onBackClick}>
            <Styled marginRight="1rem">
              <ArrowLeft />
            </Styled>
            {backLabel}
          </Back>
        )}

        {showBackButton && (
          <BreadcrumbContainer>
            <IconAndTitleWrapper onClick={handleBackButton}>
              <ChevronLeft />
              <span>Sessions</span>
            </IconAndTitleWrapper>
          </BreadcrumbContainer>
        )}

        {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
          
        <Title>
          {!showDetails && (
            <Text variant={isMobile ? "large" : "extraLarge3"}>{currentPage ? currentPage.label : title}</Text>
          )}

          {showDetails && (
            <TitleCard isMobile>
              {isLoading ? (
                <Loader size="small" />
              ) : (
                <TitleImg
                src={logoUrl}
                isLoading={isLoading}
                isMobile={isMobile}
                isRound={true}
                />     
              )}  

              <TitleDetail>
                <Text className="details-title">
                  {currentPage ? currentPage.label : title}
                </Text>
                <Text
                  className="details"
                  variant={isMobile ? "xsMedium" : "medium"}
                >
                  {subtitleDetailText}
                </Text>
              </TitleDetail>
            </TitleCard>
          )}
        </Title>

        <Description>
          {currentPage && (
            <LeftCol>
              {(currentPage.description || description) && !isMobile && (
                <Text variant="medium">{currentPage ? currentPage.description : description}</Text>
              )}
            </LeftCol>
          )}
          {rightContent && <RightCol>{rightContent}</RightCol>}
        </Description>
      </Header>
      <Content>{children}</Content>
      {footer}
    </Main>
  );
};

export const PageLoader = () => (
  <PageLoaderContainer>
    <Loader />
  </PageLoaderContainer>
);

export default Page;
