import moment from "moment";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import ArrowRight from "../../components/icons/arrow-right";
import Trending from "../../components/icons/trending";
import Timeline from "../../components/icons/timeline.svg";
import Trainee from "../../components/icons/trainee.svg";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Page, { PageLoader } from "../../components/ui/page";
import Styled from "../../components/ui/styled";
import { useFeatureToggles } from "../../context/feature-toggles";
import { Features } from "../../enums/features";
import { Pages } from "../../enums/pages";
import useQueryParams from "../../utils/use-query-params";
import ActionsPerformed from "./components/actions-performed";
import SessionDetailCharts from "./components/custom-charts";
import Score from "./components/score";
import SessionDetailTopStats from "./components/top-stats";
import { ButtonScenarioOverview, Main, Section, StyledImg, StyledImgTimeline } from "./session-detail.styles";
import { SessionProvider, useSession } from "../../context/session";
import { LandingScore } from "./components/landing-score";
import CustomTopStats from "./components/custom-top-stats";
import { getTimeDifference } from "./components/timeline-chart-with-firing/utils";
import TimelineChartFiring from "./components/timeline-chart-with-firing";

const localeFn = (target: string) =>
  import(`./locale/${target.toLowerCase()}.json`);

type Props = {};

const SessionDetail = ({}: Props) => {
  const { isFeatureActive } = useFeatureToggles();
  const { sessionPeriodFrom, sessionPeriodTo } =
    useSession();
  const intl = useIntl();
  const history = useHistory();
  const params: any = useQueryParams();
  const name = params.get("name");
  const simulationName = params.get("simulationName");
  const traineeName = params.get("traineeName");
  const logoUrl = params.get("logoUrl");
  const sessionDuration = getTimeDifference(new Date(sessionPeriodFrom),new Date(sessionPeriodTo));
  const { state }: {state: Record<string, string>} = useLocation();

  const handleOnClickOverview = () => {
    history.push(
      `${Pages.SessionsOverview}?userId=${params.get(
        "userId"
      )}&name=${encodeURIComponent(
        name
      )}&from=${sessionPeriodFrom}&to=${sessionPeriodTo}`
    );
  };

  const getSubtitleSessionLabel = () => {
    const sessionDate = moment(sessionPeriodFrom || sessionPeriodTo).format(
      "DD/MM/YYYY"
    );
    const fromTime = moment(sessionPeriodFrom).format("HH:mm");

    return (
      <span>
        <StyledImg width={24} src={Trainee} />
        {state?.traineeName || traineeName}
        <StyledImgTimeline width={20} src={Timeline} />
        {sessionDate} {`${fromTime} \u2022 ${sessionDuration}`}
      </span>
    );
  };
  const offset: number = 200;

  const sessionTitle = name || intl.formatMessage({ id: "session-detail:title" });
  const title = `${state?.simulationName || simulationName || ''} \u2022 ${sessionTitle}`

  return (
    <Page
      pageTitle={false}
      title={title}
      showBackButton={true}
      showDetails={true}
      subtitleDetailText={getSubtitleSessionLabel()}
      logoUrl={state?.logoUrl || logoUrl}
    >
      <Main>
        {isFeatureActive(Features.SessionOverview) && (
          <ButtonScenarioOverview onClick={handleOnClickOverview}>
            <Styled marginRight={"0.65rem"}>
              <FormattedMessage id="session-detail:scenario-overview" />
            </Styled>
            <ArrowRight />
          </ButtonScenarioOverview>
        )}

        <LandingScore />

        <CustomTopStats />

        <TimelineChartFiring />

        {/* {isFeatureActive(Features.SessionDetailTopStats) && (
          <Section.Container>
            <Section.Header justifyContent="space-between">
              <Styled display="flex" alignItems="center">
                <Styled marginRight={"1rem"} display="flex" alignItems="center">
                  <Trending width={22} height={22} />
                </Styled>
                <FormattedMessage id="top-stats" />
              </Styled>
            </Section.Header>
            <Section.Body hasBackground={false}>
              <SessionDetailTopStats />
            </Section.Body>
          </Section.Container>
        )} */}

        {isFeatureActive(Features.SessionDetailScore) && (
          <Section.Container>
            <Section.Header justifyContent="space-between">
              <Styled display="flex" alignItems="center">
                <Styled marginRight={"1rem"} display="flex" alignItems="center">
                  <Trending width={22} height={22} />
                </Styled>
                <FormattedMessage id="session-detail:score" />
              </Styled>
            </Section.Header>
            <Section.Body hasBackground={false}>
              <Score />
            </Section.Body>
          </Section.Container>
        )}

        {isFeatureActive(Features.SessionDetailActionsPerformed) && (
          <ErrorBoundary
            fallback={
              <Styled color="gray[100]" textAlign="center">
                <FormattedMessage id="session-detail:actions-performed:error" />
              </Styled>
            }
          >
            <Suspense fallback={null}>
              <ActionsPerformed offset={offset} />
            </Suspense>
          </ErrorBoundary>
        )}

        {/* <SessionDetailCharts /> */}
      
      </Main>
    </Page>
  );
};

const SessionDetailPage = (props: Props) => (
  <VRIntlProviderComponent
    localeFn={localeFn}
    id="session-detail"
    fallback={<PageLoader />}
  >
    <SessionProvider>
      <SessionDetail {...props} />
    </SessionProvider>
  </VRIntlProviderComponent>
);

export default SessionDetailPage;
