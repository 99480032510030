import React, { useEffect, useState } from "react";
import { ProgressBar } from "./score-chart";
import { Section } from "./score-chart.styles";
import { FormattedMessage } from "react-intl";
import TooltipSVG from '../../../../components/icons/tooltip.svg'
import { triggerAnalytics } from "../../../../api/sessions";
import Loader from "../../../../components/ui/loader";
import {
    StyledImg,
    StyledImgMargin,
    CustomTooltip,
    TooltipContent,
    Title,
    SubTitle,
    Divider,
    LoaderContainer
  } from "../../session-detail.styles";
import { usePolling } from "./usePolling";

export type Score = {
    scoreTitle: string,
    score: number,
    description: string,
    position: number
}

export const LandingScore = () => {
    const [poll, setPoll] = useState(0);
    const {landingScore, breakdownData, simulationInstanceId} = usePolling(poll);
    const breakdownDataSorted: any = breakdownData?.sort((a,b) => a.position - b.position);

    useEffect(() => {
        if(breakdownData?.length === 0) {
            triggerAnalytics({simulationInstanceId});
            setTimeout(() => {
                setPoll(poll+1);
            }, 10000);
        }
    }, [breakdownData]);

    if(breakdownData === undefined) {
        return <LoaderContainer><Loader /></LoaderContainer>;;
    }

    return(
        <Section.Container>
            {breakdownData.length === 0 ? <Section.NoData>
                <Section.NoDataContent>
                    <Loader></Loader>
                    <Title>
                        <FormattedMessage id={"session-detail:data-loading"} />
                    </Title>
                </Section.NoDataContent>
            </Section.NoData> 
            :
            <>
                {landingScore && <Section.LandingScore>
                    <Title>
                        {landingScore.scoreTitle}
                        <CustomTooltip title={
                                <TooltipContent>
                                    <StyledImg src={TooltipSVG} width="14px" />
                                    {landingScore.description}
                                </TooltipContent>
                            } arrow>
                            <StyledImg alt="tooltip" src={TooltipSVG} width="18px"/>
                        </CustomTooltip>
                    </Title>

                    <ProgressBar width={320} valueEnd={Math.round(landingScore.score)} scoreFontSize="6rem" totalScoreFontSize="3rem"/>
                    
                    {/* <Section.Description> 
                        <FormattedMessage id={"session-detail:score-description"} />
                        <CustomTooltip title={
                                <TooltipContent>
                                    <StyledImg src={TooltipSVG} width="14px" />
                                    <FormattedMessage id={"session-detail:tooltip-landing-score-desc"} />
                                </TooltipContent>
                            } arrow>
                            <StyledImgMargin alt="tooltip" src={TooltipSVG} width="18px"/>
                        </CustomTooltip>
                    </Section.Description> */}
                </Section.LandingScore>}

                {breakdownData?.length !== 0 && <Section.Breakdown>
                    <Title>
                        <FormattedMessage id={"session-detail:score-breakdown"} />
                    </Title>

                    <Section.BreakDownContainer> 
                        {breakdownDataSorted?.map((score:Score,index:number) => {
                            return <React.Fragment key={score.scoreTitle}>
                                <Section.SubContainer>
                                    <ProgressBar width={192} valueEnd={Math.trunc(score.score)} scoreFontSize="3rem" totalScoreFontSize="1.5rem"/>
                                    
                                    <SubTitle> 
                                        <>{score.scoreTitle}</>
                                        <CustomTooltip title={
                                                <TooltipContent>
                                                    <StyledImg src={TooltipSVG} width="14px" />
                                                    {score.description}
                                                </TooltipContent>
                                            } arrow>
                                            <StyledImgMargin alt="tooltip" src={TooltipSVG} width="18px"/>
                                        </CustomTooltip>
                                    </SubTitle>
                                </Section.SubContainer>
                                {index < (breakdownData.length-1) && <Divider/>}
                            </React.Fragment> 
                            })           
                        }    
                    </Section.BreakDownContainer> 
                </Section.Breakdown>  
                }
            </>
            }
        </Section.Container>
    )
 }