import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
    position: fixed;
    height: 100vh;
    width: 20rem;
    top: 0;
    left: 0;
    padding-bottom: 10rem;
    z-index: 999;
    border-right: 1px solid #3F3F3F;
    background: #2B282A;

    @media (max-width:1024px) {
        height: auto;
        width: 100%;
        padding-bottom: 0;
    }
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 1.5rem;
    box-sizing: border-box;
    display: flex;

    @media (max-width:1024px) {
        padding: 0;
    }
`;

export const Logo = styled.img`
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #3F3F3F;
    margin-bottom: 1.5rem;
    width: 100%;
`;

export const Nav = styled.nav`
    width: 100%;
`

export const MenuContainer = styled.div`
    width: 100%;

    &::-webkit-scrollbar {
        height: 0.2rem;
        display: none;
    }

    &:hover::-webkit-scrollbar {
        display: block;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #1A1A1A;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #666;
        border-radius: 0.35rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media screen and (max-width:767px) {
        overflow: auto;
        padding: 0 0.5rem;
        box-sizing: border-box;
        /* width */
       
    }
`;

export const Menu = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    
    @media (max-width:1024px) {
        flex-direction: row;
        justify-content: center;
        border-top: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray[100]};
        padding-top: 1rem;
    }
    @media screen and (max-width:767px) {
        justify-content: flex-start;
        border-bottom: none;
    }
`;

export const MenuItem = styled.li`
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
    list-style: none;
    display: flex;
    padding: 0.5rem 0;
    height: 2rem;
    border-radius: 2px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;

    &:hover {
        font-weight: 500;
        background-color: #3F3F3F;

        > div:first-child {
            background-color: #F2F2F2;
        }
    }

    @media screen and (max-width:767px) {
        display: inline-block;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }
    
`;

export const MenuLink = styled.div`
    color: #F2F2F2;
    font-style: normal;
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    text-decoration: none;
    border-radius: 2rem;
    transition: all 0.1s ease-in-out;
    outline: none;
    padding-left: 0.5rem;
    
    & svg path {
        fill: #F2F2F2;
        transition: all 0.1s ease-in-out;
    }

    &.active {
        > span {
            font-weight: 500;
        }

        & svg path {
            fill: #EE7127 !important;
        }
    }

    @media (max-width:1024px) {
        padding: 0.65rem 1.6rem;
    }
`;

export const MenuItemIndicator = styled.div<{ isActive: boolean }>`
    width: 4px;
    height: 1.5rem;
    background-color: ${({ isActive }) => (isActive ? '#EE7127 !important' : 'transparent')};
    border-radius: 0px 4px 4px 0px;
    position: relative;
    top: 5px;
    transition: all 0.1s ease-in-out;
`;

export const IconWrapper = styled.span`
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    position: relative;
    top: -5px;
    left: 0px;
    width: fit-content;
`;

export const SectionTitle = styled.h2`
  color: #999;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  margin-bottom: 0.75rem;
  margin-top: 2rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  text-transform: uppercase;

  &:first-child {
    margin-top: 0;
  }
`;