import { Container, Wrapper, LogoutWrapper } from "./styles";
import UserBadge from "../user-badge";
import { LogoutIcon } from "../../components/icons";
import { useMsal } from "@azure/msal-react";

const TopBar = () => {
  const { instance } = useMsal();

  const handleLogOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <Wrapper>
      <Container>
        <LogoutWrapper onClick={handleLogOut}>
          <LogoutIcon />
        </LogoutWrapper>
        <UserBadge />
      </Container>
    </Wrapper>
  );
};

export default TopBar;
