import styled from "styled-components";

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 2.25rem;
  right: 1.5rem;

  @media (max-width: 55.25rem) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
  }
`;

export const Container = styled.div``;

export const SessionItemContainer = styled.div`
  display: flex;
  height: 6.6875rem;
  width: 100%;
  padding: 1.125rem 1.5rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  background: #2B282A;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  cursor: pointer;
  transition: all 300ms;

  & .chevron-container svg path {
    transition: all 300ms;
  }

  &:hover {
    background: #3F3F3F;

    .chevron-container svg path {
      fill: #F2F2F2;
    }
  }
`;

export const SessionIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 4rem;
`;

export const SessionInfoContainer = styled.div`
  width: calc(100% - 8rem);
  height: 100%;
`;

export const SessionTitle = styled.div`
  margin-bottom: 0.5rem;
  color: #F2F2F2;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.015rem;

  .bullet-point {
    margin-left: 0.5rem;
  }
`;

export const SessionDetails = styled.div`
  display: flex;
  color: #999;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6875rem;
  letter-spacing: 0.01125rem;
  gap: 0.5rem;
`;

export const DetailItem = styled.div`
  gap: 0.25rem;
  display: flex;
  align-items: center;
`;

export const DetailItemText = styled.span`
  margin-left: 0.25rem;
`;

export const TimeIconContainer = styled.div`
  position: relative;
  top: 3px;
`;

export const BulletPoint = styled.span`
  font-size: 1.25rem;
  margin-right: 0.5rem;
  padding-top: 1px;
`;

export const SessionsContainer = styled.div`
  display: flex;
`;

export const SessionsMainContent = styled.div`
  @media (max-width: 2560px){
    width: 100%;
  }
 
  @media (min-width: 2560px){
    width: calc(2560px - (20rem + 1.5rem)); //subtracting the navigation width
  }
`;

export const SessionsSidebar = styled.div`
  width: 33%;
`;

export const ChevronContainer = styled.div`
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem 0;
`;

export const LoaderFixedContainer = styled.div`
  position: fixed;
  width: calc(100% - 20.0625rem);
  height: calc(100vh - 7.5625rem);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 21.0625rem;
`;