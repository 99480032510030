import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { DebounceInput } from "react-debounce-input";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage, useIntl } from "react-intl";
import { getTrainees } from "../../../api/trainee";
import { SearchIcon, SortIcon, UserCircleIcon, ValidIcon, UsersIcon } from "../../../components/icons";
import Avatar from "../../../components/ui/avatar";
import Loader from "../../../components/ui/loader";
import Text from "../../../components/ui/text";
import { FiltersList } from "../../../enums/dashboard";
import { Pages } from "../../../enums/pages";
import { Trainee, TraineeResponse } from "../../../types/trainee";
import {
  AllTrainees,
  AvatarContainer,
  Container,
  Item,
  Link,
  List,
  ListContainer,
  LoadMore,
  LoaderContainer,
  Name,
  Search,
  Toggle,
  TraineesPopUp,
  AllTraineesContainer,
  AvatarIconContainer,
} from "./filters.styles";
import { buildUrl } from "./utils";

const buildTraineeLink = (id: string | null, filters: any) =>
  `${id ? `${Pages.Dashboard}/trainee/${id}` : Pages.DashboardAllTrainees}${buildUrl(filters)}`;

const mapTraineeResponse = ({ userId, displayName, createDate, email, lastInvitationSent, status }: TraineeResponse): Trainee => ({
  id: userId,
  email,
  name: displayName,
  dateAdded: createDate,
  lastInvitationSent,
  status,
});

const fetchTrainees = async (token: string | null, search: string) => await getTrainees(token, search);

type Props = {
  isOpen: boolean;
  handleOnClick: (name: string | null) => void;
  onUserSelected: (userId: string | null) => void; // Allow null here
  initialUserId: string | null;
};

const TraineesFilter = ({ isOpen, handleOnClick, onUserSelected: onUserSelectedProp, initialUserId }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [userId, setUserId] = useState<string | null>(initialUserId);
  const [page, setPage] = useState(1);
  const [token, setToken] = useState(null);
  const [trainees, setTrainees] = useState<Trainee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setUserId(initialUserId);
  }, [initialUserId]);

  useEffect(() => {
    setIsLoading(true);
    fetchTrainees(token, search)
      .then(({ skipToken, items }) => {
        setToken(skipToken);
        setTrainees(items.map((res: TraineeResponse) => mapTraineeResponse(res)) || []);
        setIsLoading(false);
      })
      .catch(() => {});
  }, [page, search]);

  const handleSearchChange = useCallback(
    (e: any) => {
      setToken(null);
      setTrainees([]);
      setSearch(e.target.value);
      setPage(1);
    },
    [search]
  );

  const handleToggleList = useCallback(
    (e: any) => {
      handleOnClick(isOpen ? null : FiltersList.TraineesList);
    },
    [isOpen]
  );

  const handleUserSelected = useCallback((userId: string | null) => { // Allow null here
    setUserId(userId);
    if (userId) {
      onUserSelectedProp(userId);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('traineeId', userId);
      history.push({ search: queryParams.toString() });
    } else {
      onUserSelectedProp(null);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete('traineeId');
      history.push({ search: queryParams.toString() });
    }
  }, [onUserSelectedProp, history]);

  const trainee = trainees.find(({ id }) => id === userId) || null;

  return (
    <Container>
      <Toggle onClick={handleToggleList}>
        {trainee ? (
          <>
            <UserCircleIcon />
            <Name>
              <Text>{trainee.name}</Text>
            </Name>
            <SortIcon />
          </>
        ) : (
          <>
            <UserCircleIcon />
            <Name>
              <FormattedMessage id="dashboard:all-trainees" />
            </Name>
            <SortIcon />
          </>
        )}
      </Toggle>
      {isOpen && (
        <ListPopUp
          search={search}
          onSearchChange={handleSearchChange}
          selected={userId} // Use local userId state
          loading={isLoading}
          trainees={trainees}
          token={token}
          onLoadMore={() => setPage(page + 1)}
          onClose={() => handleOnClick(null)}
          onClick={() => handleOnClick(null)}
          onUserSelected={handleUserSelected} // Pass the new handler here
        />
      )}
    </Container>
  );
};

const ListPopUp = ({
  search,
  onSearchChange,
  selected,
  loading,
  token,
  trainees,
  onLoadMore,
  onClose,
  onClick,
  onUserSelected,
}: {
  search: string;
  onSearchChange: (e: any) => void;
  selected: string | null; // Allow null here
  loading: boolean;
  token: string | null;
  trainees: Trainee[];
  onLoadMore: () => void;
  onClose: () => void;
  onClick: (e: any) => void;
  onUserSelected: (userId: string | null) => void; // Allow null here
}) => {
  const clickOutsideRef = useDetectClickOutside({ onTriggered: onClose });

  const isAllSelected = selected === null;

  return (
    <TraineesPopUp ref={clickOutsideRef} width={"19.5rem"}>
      {/*<Search>
        <SearchIcon />
        <DebounceInput
          disabled={loading}
          debounceTimeout={200}
          value={search}
          onChange={onSearchChange}
          placeholder={intl.formatMessage({ id: "dashboard:search-for-trainee" })}
        />
      </Search>*/}
      {!search && (
        <AllTrainees
          selected={isAllSelected}
          onClick={(e) => {
            onClick(e);
            onUserSelected(null);
          }}
          to='#'>
          <AllTraineesContainer>
            <AvatarContainer>
              <AvatarIconContainer>
                <UsersIcon width="16" height="16" />
              </AvatarIconContainer>
            </AvatarContainer>
            <FormattedMessage id="dashboard:all-trainees" />
          </AllTraineesContainer>
        </AllTrainees>
      )}
      <ListContainer>
        <List>
          {trainees.map(({ id, name }, i) => (
            <Item 
              key={id}
              selected={selected === id}
              onClick={(e) => {
                onClick(e);
                onUserSelected(id);
              }}>
              <AvatarContainer>
                <Avatar name={name} size="small" editable={false} />
              </AvatarContainer>
              <Name className="name">
                <Text>{name}</Text>
              </Name>
            </Item>
          ))}
          {loading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          {!loading && token && (
            <LoaderContainer>
              <LoadMore onClick={onLoadMore}>
                <FormattedMessage id="dashboard:load-more" />
              </LoadMore>
            </LoaderContainer>
          )}
        </List>
      </ListContainer>
    </TraineesPopUp>
  );
};

export default TraineesFilter;
