import { IconProps } from "../../types/icon";

const Sessions = ({ width = 20, height = 20, fill = "#EE7127" }: IconProps) =>
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Sessions Icon">
      <path
        id="Sessions Icon_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7771 1.98995C18.1733 1.98995 17.5702 1.98833 16.9674 1.9867C15.4613 1.98264 13.9569 1.97858 12.4484 1.9999C11.8564 2.00985 11.3283 1.26362 11.6564 0.517388C11.8244 0.139297 12.0964 0 12.4324 0H15.0407H25.0497C25.6818 0 26.0818 0.507438 25.9858 1.16412C25.9058 1.68151 25.5858 1.98 25.0977 1.98995H20.1612H18.7771ZM9.01329 7.13093V4.51422V4.50609V1.85688C9.00512 0.767946 8.27785 0.020316 7.19102 0.0121896C5.40144 -0.0040632 3.61186 -0.0040632 1.82227 0.0121896C0.735446 0.0284424 0 0.792325 0 1.87314V7.09842C0 8.23612 0.768132 8.99187 1.91216 9H7.13382C8.25333 8.99187 9.01329 8.24424 9.01329 7.13093ZM9.01329 21.5509V21.5427V18.8969C9.00512 17.749 8.26082 17 7.11576 17H1.88936C0.768829 17 0 17.7571 0 18.8643V24.1398C0.00817903 25.2144 0.744292 25.9796 1.8321 25.9878C3.62331 26.0041 5.41452 26.0041 7.20573 25.9878C8.27718 25.9796 9.00512 25.2307 9.01329 24.1642V21.5509ZM18.7771 18.99C18.1733 18.99 17.5702 18.9883 16.9673 18.9867H16.9672H16.967H16.9669C15.4609 18.9826 13.9567 18.9786 12.4484 18.9999C11.8564 19.0099 11.3283 18.2636 11.6564 17.5174C11.8244 17.1393 12.0964 17 12.4324 17H15.0407H25.0497C25.6818 17 26.0818 17.5074 25.9858 18.1641C25.9058 18.6815 25.5858 18.98 25.0977 18.99H20.1612H18.7771ZM20.4145 20.0101H16.3996V20H12.3847C11.8934 20 11.5556 20.4242 11.5556 21.0101C11.5633 21.5758 11.9011 22 12.3693 22H20.3991C20.545 22 20.6985 21.9495 20.8214 21.8586C21.1208 21.6364 21.2436 21.1717 21.1591 20.7273C21.0747 20.3131 20.7753 20.0101 20.4145 20.0101ZM20.4145 3.0101H16.3996V3H12.3847C11.8934 3 11.5556 3.42424 11.5556 4.0101C11.5633 4.57576 11.9011 5 12.3693 5H20.3991C20.545 5 20.6985 4.9495 20.8214 4.85859C21.1208 4.63636 21.2436 4.17172 21.1591 3.72727C21.0747 3.31313 20.7753 3.0101 20.4145 3.0101Z"
        fill={fill}
      />
    </g>
  </svg>

export default Sessions;



